import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { hydrate, render } from "react-dom";
import App from "./App";
import "./font/Manrope/Manrope-Bold.ttf";
import "./font/Manrope/Manrope-ExtraBold.ttf";
import "./font/Manrope/Manrope-ExtraLight.ttf";
import "./font/Manrope/Manrope-Light.ttf";
import "./font/Manrope/Manrope-Medium.ttf";
import "./font/Manrope/Manrope-Regular.ttf";
import "./font/Manrope/Manrope-SemiBold.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     rootElement
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
