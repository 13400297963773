import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/button/Button";
import BGImage from "../../assets/backgroundImg.png";

import BGImageSmall from "../../assets/BGImageSmall.png";
import burgerMenu from "../../assets/burgerMenu.svg";
import LogoImage from "../../assets/logo.png";

import "./Main.css";
import { useMediaQuery } from "react-responsive";
import Header from "../../components/header/Header";
import SecondBlockBig from "../../components/secondBlockBig/SecondBlockBig";
import SecondBlockSmall from "../../components/secondBlockSmall/SecondBlockSmall";
import Services from "../../components/services/Services";
import ServicesSmall from "../../components/servicesSmall/ServicesSmall";
import PortfolioSmall from "../../components/portfolioSmall/PortfolioSmall";
import FoorterBig from "../../components/foorterBig/FoorterBig";
import FoorterSmall from "../../components/foorterSmall/FoorterSmall";
import ReviewBig from "../../components/reviewBig/ReviewBig";
import ReviewSmall from "../../components/reviewSmall/ReviewSmall";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import "react-phone-input-2/lib/style.css";
import emailjs from "@emailjs/browser";
import Driver from "../../components/driver/Driver";
import ModalBig from "../../components/modalBig/ModalBig";
import ModalSmall from "../../components/modalSmall/ModalSmall";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

const Main = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const form = useRef();
  const servicesRef = useRef();
  const contactsRef = useRef();
  const portfolioRef = useRef();
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onServiceList = () => {};

  Modal.setAppElement("#root");
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isDriverOpen, setIsDriverOpen] = React.useState(false);

  const scrollToServices = () => {
    if (isDesktopOrLaptop) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setIsDriverOpen(false);
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (isDesktopOrLaptop) {
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setIsDriverOpen(false);
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const onPriceList = () => {
  //   setIsDriverOpen(false);

  //   navigate("/price-list");
  // };

  const scrollToPortfolio = () => {
    setIsDriverOpen(false);

    portfolioRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [name, setName] = useState();
  const [nameError, setNameError] = useState(false);

  const [number, setNumber] = useState();
  const [numberError, setNumberError] = useState(false);

  useEffect(() => {
    if (state) {
      navigate("/", { replace: true, state: false });
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [navigate, state]);

  const onChangeName = (event) => {
    setNameError(false);
    setName(event.target.value);
  };

  useEffect(() => {
    if (modalIsOpen || isDriverOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isDriverOpen, modalIsOpen]);

  const onChangeNumber = (event) => {
    setNumberError(false);
    setNumber(event);
  };

  const openModal = () => {
    if (isDesktopOrLaptop) {
      setIsOpen(true);
    } else {
      setIsDriverOpen(false);
      setIsOpen(true);
    }
  };

  const openDriverMenu = () => {
    if (!modalIsOpen) {
      setIsDriverOpen((state) => !state);
    }
  };

  const closeModal = () => {
    setIsloading(false);
    setIsSuccess(false);
    setIsError(false);
    setIsOpen(false);
    setNumberError(false);
    setNameError(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name) {
      setNameError(true);
    } else if (number?.length !== 12) {
      setNumberError(true);
    } else if (name && number?.length === 12) {
      setIsloading(true);
      emailjs
        .sendForm(
          "service_b6j173o",
          "template_c01hcyf",
          form.current,
          "Ne5wd-VaeTIsJTuDX"
        )
        .then(
          (result) => {
            setIsloading(false);
            setIsSuccess(true);
            console.log(result.text);
          },
          (error) => {
            setIsloading(false);
            setIsError(true);
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div className="mainContauner">
      {isDesktopOrLaptop ? (
        <header className="header_container">
          <Header
            onServices={scrollToServices}
            onServiceList={onServiceList}
            onContact={scrollToContact}
          />
        </header>
      ) : (
        <div className="header_container_small">
          <div style={{ width: "30px" }} />
          {/* <p className="header_title">black rock custom</p> */}
          <img
            src={LogoImage}
            alt="backgroundImage"
            className="logo_small"
            // effect="blur"
            loading="lazy"
          />

          <img
            src={burgerMenu}
            alt="burgerMenu"
            onClick={openDriverMenu}
            // effect="blur"
            loading="lazy"
          />
        </div>
      )}
      {isDesktopOrLaptop ? null : (
        <Driver
          isDriverOpen={isDriverOpen}
          isMain={true}
          scrollToServices={scrollToServices}
          scrollToPortfolio={scrollToPortfolio}
          onContact={scrollToContact}
          openModal={openModal}
        />
      )}
      <main>
        <section
          className="container_banner"
          onClick={() => {
            if (isDriverOpen) {
              setIsDriverOpen(false);
            }
          }}
        >
          {BGImage || BGImageSmall ? (
            <img
              src={isDesktopOrLaptop ? BGImage : BGImageSmall}
              alt="backgroundImage"
              className={isDesktopOrLaptop ? "bg_image" : "bg_image_small"}
              // effect="blur"
              loading="lazy"
            />
          ) : (
            <div
              className={
                isDesktopOrLaptop ? "bg_no_image" : "bg_no_image_small"
              }
            />
          )}
          <div
            className={
              isDesktopOrLaptop ? "info_container" : "info_container_small"
            }
          >
            <h1
              className={
                isDesktopOrLaptop ? "invisible_title" : "main_title_mobile"
              }
            >
              АВТОАТЕЛЬЕ В МИНСКЕ
            </h1>
            <p className={isDesktopOrLaptop ? "title" : "title_small"}>
              Мы — команда профессионалов в области детейлинга, любящих
              автомобили всем сердцем.
            </p>
            <Button title="КОНСУЛЬТАЦИЯ" onClick={openModal} />
          </div>
        </section>
        <section>
          {isDesktopOrLaptop ? <SecondBlockBig /> : <SecondBlockSmall />}
        </section>
        <section>
          {isDesktopOrLaptop ? (
            <Services servicesRef={servicesRef} />
          ) : (
            <ServicesSmall servicesRef={servicesRef} />
          )}
        </section>
        <section>
          <PortfolioSmall portfolioRef={portfolioRef} />
        </section>

        {/* {isDesktopOrLaptop ? (
        <PortfolioBig />
      ) : (
        <PortfolioSmall portfolioRef={portfolioRef} />
      )} */}
        <section>{isDesktopOrLaptop ? <ReviewBig /> : <ReviewSmall />}</section>
        <footer>
          {isDesktopOrLaptop ? (
            <FoorterBig contactsRef={contactsRef} />
          ) : (
            <FoorterSmall contactsRef={contactsRef} />
          )}
        </footer>
        {isDesktopOrLaptop ? (
          <ModalBig
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            form={form}
            onChangeName={onChangeName}
            onChangeNumber={onChangeNumber}
            sendEmail={sendEmail}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            nameError={nameError}
            numberError={numberError}
          />
        ) : (
          <ModalSmall
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            form={form}
            onChangeName={onChangeName}
            onChangeNumber={onChangeNumber}
            sendEmail={sendEmail}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            nameError={nameError}
            numberError={numberError}
          />
        )}
      </main>
    </div>
  );
};

export default Main;
