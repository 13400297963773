import { useState } from "react";
import "./Services.css";
import { all, outside, inside } from "./servicesList";
import { Link } from "react-router-dom";

const Services = ({ servicesRef }) => {
  const [servicesList, setServicesList] = useState({ index: 1, list: all });

  const onPress = (service) => {
    if (service === 1) {
      setServicesList({ index: 1, list: all });
    }
    if (service === 2) {
      setServicesList({ index: 2, list: outside });
    }
    if (service === 3) {
      setServicesList({ index: 3, list: inside });
    }
  };

  return (
    <div className="services_container" ref={servicesRef}>
      <span className="title_services">УСЛУГИ</span>
      <div className="option_services_container">
        <span
          className={servicesList.index === 1 ? "option_active" : "option"}
          onClick={() => onPress(1)}
        >
          ВСЕ
        </span>
        <span
          className={servicesList.index === 2 ? "option_active" : "option"}
          onClick={() => onPress(2)}
        >
          КУЗОВ
        </span>
        <span
          className={servicesList.index === 3 ? "option_active" : "option"}
          onClick={() => onPress(3)}
        >
          САЛОН
        </span>
      </div>
      <div className="services_img_container">
        {servicesList.list.map((it) => (
          <Link to={`/service/${it.link}`} key={it.id}>
            <div className="services_img_margin">
              <img
                src={it.img}
                alt="burgerMenu"
                className="services_img"
                // effect="blur"
                loading="lazy"
              />
              <span className="services_img_title">{it.title}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Services;
