import pastingAntigravelFilm from "../../assets/services/pastingAntigravelFilm.png";
import vinylWrapping from "../../assets/services/vinylWrapping.png";
import polishing from "../../assets/services/polishing.png";
import ceramic from "../../assets/services/ceramic.png";
import vosk from "../../assets/services/vosk.png";
import antiRain from "../../assets/services/antiRain.png";
import soundIsolation from "../../assets/services/soundIsolation.png";
import cleaning from "../../assets/services/cleaning.png";
import interiorRestoration from "../../assets/services/interiorRestoration.png";
import alteringInterior from "../../assets/services/alteringInterior.png";

export const all = [
  {
    title: "перешив салона",
    img: alteringInterior,
    id: 10,
    link: "altering-interior",
  },
  {
    title: "реставрация кожи",
    img: interiorRestoration,
    id: 9,
    link: "interior-restoration",
  },
  { title: "химчистка", img: cleaning, id: 8, link: "cleaning" },
  {
    title: "полировка",
    img: polishing,
    id: 3,
    link: "polishing",
  },
  { title: "керамика", img: ceramic, id: 4, link: "ceramic" },
  { title: "воск", img: vosk, id: 5, link: "wax" },
  { title: "антидождь", img: antiRain, id: 6, link: "anti-rain" },
  {
    title: "оклейка антигравийной пленкой",
    img: pastingAntigravelFilm,
    id: 1,
    link: "pasting-antigravel-film",
  },
  {
    title: "оклейка виниловой пленкой",
    img: vinylWrapping,
    id: 2,
    link: "vinyl-wrapping",
  },
  {
    title: "шумоизоляция",
    img: soundIsolation,
    id: 7,
    link: "sound-isolation",
  },
];

export const inside = [
  { title: "химчистка", img: cleaning, id: 8, link: "cleaning" },
  {
    title: "реставрация кожи",
    img: interiorRestoration,
    id: 9,
    link: "interior-restoration",
  },
  {
    title: "перешив салона",
    img: alteringInterior,
    id: 10,
    link: "altering-interior",
  },
];

export const outside = [
  {
    title: "оклейка антигравийной пленкой",
    img: pastingAntigravelFilm,
    id: 1,
    link: "pasting-antigravel-film",
  },
  {
    title: "оклейка виниловой пленкой",
    img: vinylWrapping,
    id: 2,
    link: "vinyl-wrapping",
  },
  {
    title: "полировка",
    img: polishing,
    id: 3,
    link: "polishing",
  },
  { title: "керамика", img: ceramic, id: 4, link: "ceramic" },
  { title: "воск", img: vosk, id: 5, link: "wax" },
  { title: "антидождь", img: antiRain, id: 6, link: "anti-rain" },
  {
    title: "шумоизоляция",
    img: soundIsolation,
    id: 7,
    link: "sound-isolation",
  },
];
