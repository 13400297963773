import "./FoorterBig.css";
import instagramIcon from "../../assets/instagramIcon.png";
import tiktokIcon from "../../assets/tiktok.png";
import footerLogo from "../../assets/footerLogo.png";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import mapImg from "../../assets/mapImg.png";
import { useNavigate } from "react-router-dom";

const FoorterBig = ({ contactsRef }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCoCkEl0L3FilimbHGywQerFAcUf1MKBwY",
  });

  const navigate = useNavigate();

  const onNavigateToService = () => {
    navigate("/", { state: true });
  };
  return (
    <div className="footer_container" ref={contactsRef}>
      <div className="footer_container_icon">
        <div className="footer_text_container">
          <span className="footer_text_adress">
            г. Минск Меньковский тракт 2 авторынок малиновка
          </span>
          <span className="footer_text">Пн - Пт с 11:00 до 20:00 </span>
          <span className="footer_text_weekend">Сб/Вс - выходной</span>
          <span className="footer_text_number">+375 (33) 688-93-93</span>
          <div className="instagram_container">
            <img
              src={instagramIcon}
              className="instagram_icon"
              alt="instagram_icon"
              // effect="blur"
              loading="lazy"
            />
            <p>
              <a
                href="https://instagram.com/blackrock.custom?igshid=MzRlODBiNWFlZA=="
                className="footer_text"
                target="_blank"
                rel="noreferrer"
              >
                instagram
              </a>
            </p>
          </div>
          <div className="instagram_container">
            <img
              src={tiktokIcon}
              className="tiktok_icon"
              alt="tiktok_icon"
              // effect="blur"
              loading="lazy"
            />
            <p>
              <a
                href="https://www.tiktok.com/@blackrock.custom?_t=8iyUi1grVi9&_r=1"
                className="footer_text"
                target="_blank"
                rel="noreferrer"
              >
                Tik Tok
              </a>
            </p>
          </div>
        </div>
        <img
          src={footerLogo}
          className="footer_img"
          alt="footer_img"
          // effect="blur"
          loading="lazy"
        />
      </div>
      <div className="footer_map_container_small">
        <div className="footer_title_map_container_small">
          <span>
            <a
              className="footer_title_map"
              href="https://yandex.ru/navi/org/76720367635"
              target="_blank"
              rel="noreferrer"
            >
              Как нас найти
            </a>
          </span>
        </div>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: "95%",
              height: "300px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
            center={{ lat: 53.859278, lng: 27.417026 }}
            zoom={18}
          >
            <Marker position={{ lat: 53.859278, lng: 27.417026 }} />
          </GoogleMap>
        ) : (
          <img
            src={mapImg}
            className="img_map_small"
            alt="img_map_small"
            // effect="blur"
            loading="lazy"
          />
        )}
      </div>
      <div className="footer_menu">
        <span className="footer_menu_option" onClick={onNavigateToService}>
          Услуги
        </span>
        <span className="footer_menu_option">Портфолио</span>
      </div>
      <div className="footer_bussines_container">
        <span className="footer_bussines_info">ЧУП «АльфаТрейлер сервис»</span>
        <span className="footer_bussines_info">
          г. Минск, ул. Тимирязева, д72
        </span>
        <span className="footer_bussines_info">УНП 193627582</span>
      </div>
    </div>
  );
};

export default FoorterBig;
