import "./ModalBig.css";
import PhoneInput from "react-phone-input-2";
import Modal from "react-modal";
import Button from "../button/Button";
import closeIcon from "../../assets/cancel.png";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#ffe500",
  padding: "100px",
};

const ModalBig = ({
  modalIsOpen,
  closeModal,
  form,
  onChangeName,
  onChangeNumber,
  sendEmail,
  isLoading,
  isSuccess,
  isError,
  nameError,
  numberError,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "25px",
          border: "1px solid rgba(104, 104, 104, 0.30)",
          background: "rgba(26, 23, 23, 0.72)",
          backdropFilter: "blur(6px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        },
      }}
      bodyOpenClassName={"body_class"}
      portalClassName={"body_class"}
      appElement={document.getElementById("root")}
      preventScroll
      // ariaHideApp={false}
    >
      {isLoading ? (
        <ClipLoader
          color={"#ffe500"}
          loading={isLoading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : isSuccess ? (
        <>
          <img
            src={closeIcon}
            className="close_icon"
            onClick={closeModal}
            alt="close_icon"
            // effect="blur"
            // loading="lazy"
          />

          <p className="modal_title_success">СПАСИБО!</p>
          <p className="modal_subtitle_success">Ваша заявка принята!</p>
          <p className="modal_subtitle_success_second">
            в ближайшее время мы с вами свяжемся!
          </p>
        </>
      ) : isError ? (
        <>
          <img
            src={closeIcon}
            className="close_icon"
            onClick={closeModal}
            alt="close_icon"
            // effect="blur"
            // loading="lazy"
          />
          <p className="modal_title_success">упс!</p>
          <p className="modal_subtitle_success">Что-то пошло не так!</p>
          <p className="modal_subtitle_success_second">
            Заявка не отправлена, пожалуйста свяжитесь с нами по номеру
            телефона.
          </p>
        </>
      ) : (
        <>
          <img
            src={closeIcon}
            className="close_icon"
            onClick={closeModal}
            alt="close_icon"
            // effect="blur"
            // loading="lazy"
          />

          <p className="modal_title">получить консультацию</p>
          <p className="modal_subtitle">
            Заполните данные ниже и мы вам перезвоним:
          </p>
          <form className="input_container" ref={form}>
            <input
              className="input"
              placeholder="имя"
              onChange={onChangeName}
              name="name"
            />
            {nameError && <span className="error_name">Введите имя</span>}
            <PhoneInput
              country={"by"}
              disableDropdown
              buttonStyle={{
                borderRadius: "14px",
                border: "1px solid rgba(161, 159, 159, 0.3)",
                background: "rgba(6, 6, 6, 0.82)",
                boxShadow: "0px 0px 5px 0px rgba(180, 177, 177, 0.25)",
                backdropFilter: "blur(6px)",
                width: "50px",
                color: "#fff",
              }}
              searchStyle={{
                background: "rgba(6, 6, 6, 0.82)",
                border: "1px solid rgba(161, 159, 159, 0.3)",
                borderRadius: "14px",
                boxShadow: "0px 0px 5px 0px rgba(180, 177, 177, 0.25)",
              }}
              inputStyle={{
                borderRadius: "14px",
                border: "1px solid rgba(161, 159, 159, 0.3)",
                background: "rgba(6, 6, 6, 0.82)",
                boxShadow: "0px 0px 5px 0px rgba(180, 177, 177, 0.25)",
                backdropFilter: "blur(6px)",
                padding: "23px",
                marginBottom: "32px",
                marginLeft: "50px",
                color: "#fff",
                width: "89%",
              }}
              containerStyle={{
                background: "rgba(6, 6, 6, 0.82)",
                border: "1px solid rgba(161, 159, 159, 0.3)",
                borderRadius: "14px",
                boxShadow: "0px 0px 5px 0px rgba(180, 177, 177, 0.25)",
                width: "100%",
              }}
              dropdownStyle={{
                background: "rgba(6, 6, 6, 0.82)",
                border: "1px solid rgba(161, 159, 159, 0.3)",
                borderRadius: "14px",
                boxShadow: "0px 0px 5px 0px rgba(180, 177, 177, 0.25)",
                width: "300px",
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              name="phone"
              onChange={onChangeNumber}
            />
            {numberError && <span className="error_number">Введите номер</span>}
          </form>
          <Button title="ОТПРАВИТЬ ЗАЯВКУ" onClick={sendEmail} />
        </>
      )}
    </Modal>
  );
};

export default ModalBig;
