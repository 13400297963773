import pastingAntigravelFilm from "../../assets/serviceImage/pastingAntigravelFilm.png";
import vinylWrapping from "../../assets/serviceImage/vinylWrapping.png";
import polishing from "../../assets/serviceImage/polishing.png";
import ceramic from "../../assets/serviceImage/ceramic.png";
import vosk from "../../assets/serviceImage/vosk.png";
import antiRain from "../../assets/serviceImage/antiRain.png";
import soundIsolation from "../../assets/serviceImage/soundIsolation.png";
import cleaning from "../../assets/serviceImage/cleaning.png";
import interiorRestoration from "../../assets/serviceImage/interiorRestoration.png";
import alteringInterior from "../../assets/serviceImage/alteringInterior.png";

export const all = [
  {
    title: "оклейка антигравийной пленкой",
    img: pastingAntigravelFilm,
    underTitle:
      "Защитите кузов своего автомобиля прочной полиуретановой пленкой.",
    id: 1,
    link: "pasting-antigravel-film",
    descriptionFirst:
      "Автоателье BlackRock выполняет оклейку автомобилей полиуретановой пленкой для защиты автомобиля от гравия, царапин, внешних воздействий и мелких ДТП. Полиуретановая пленка толщиной 215 мкм обеспечит защиту лакокрасочного покрытия от повреждений и сохранит авто в первозданном виде. Автомобиль можно оклеить целиком, а также нанести пленку локально – на основные зоны риска.",
    descriptionSecond:
      "Мы используем только сертифицированные материалы. Работаем с автомобилями всех марок и классов.",
    workTime: "Cрок работ: от 3 дней",
    material: "Материалы: Llumar, Suntek, KPMF, Hexis, 3M, Oracal",
  },

  {
    title: "оклейка виниловой пленкой",
    img: vinylWrapping,
    underTitle:
      "Мы предлагаем комплексную оклейку виниловой пленкой автомобилей. Огромный выбор пленок гарантирует, что каждый клиент найдет что-то для себя. Наша студия в Минске изменит ваш автомобиль. Сделайте ставку на качество и опыт!",
    id: 2,
    link: "vinyl-wrapping",
    descriptionFirst:
      "Оклеить автомобиль виниловой пленкой – это не только способ изменить внешний вид вашего автомобиля, но и защитить его от механических повреждений. BlackRock предлагает широкий выбор цветной виниловой пленки, что позволяет кардинально изменить внешний вид вашего автомобиля. Вы можете выбрать матовую или глянцевую пленку, а также комбинировать разные цвета и текстуры. Наши специалисты имеют большой опыт в оклейке автомобилей и гарантируют высокое качество работы, а также мы используем только качественные материалы для оклейки Вашего авто.",
    descriptionSecond:
      "Виниловая пленка легко снимается, что позволяет вернуть кузову первоначальный вид в любой момент не оставляя следов на кузове.",
    workTime: "Cрок работ: от 5 дней",
    material: "Материалы: Llumar, Suntek, KPMF, Hexis, 3M, Oracal",
  },

  {
    title: "полировка",
    img: polishing,
    underTitle:
      "Со временем лак теряет свой первоначальный блеск, и после длительного периода эксплуатации образуются многочисленные царапины и голограммы. ",
    id: 3,
    link: "polishing",
    descriptionFirst:
      "Полировка автомобиля — одна из самых популярных и, возможно, более эффектных процедур по детализации. Полировка удаляет тысячи микроцарапин, крупных царапин и голограмм, образующихся во время мойки автомобиля и его эксплуатации. Это отличный способ придать лаку свежий вид, удалить пятна и потускнения. Кроме того, полировка автомобиля придает кузову стеклянный блеск, восстанавливает его первоначальный яркий цвет и даже усиливает его глубину. Но что важно, стоит поручить действия опытным специалистам. В противном случае процедура может привести к обратному результату. Неправильная коррекция лака может чрезмерно протирать слой, покрывающий кузов, что делает его слабым и подверженным воздействию неблагоприятных погодных условий, химических веществ, влаги и ультрафиолетовых лучей. Тем не менее, стоит отметить, что если процедура была проведена должным образом, то поверхность можно защитить керамическим покрытием или защитной полиуретановой пленкой.",
    price: "Стоимость работ от 200 BYN.",
    workTime: "Cрок работ: от 1-го дня",
    type: [
      "- профессиональная полировка кузова",
      "- покрытие керамикой",
      "- полировка вставок салона",
      " - полировка фар",
    ],
  },

  {
    title: "керамика",
    img: ceramic,
    underTitle:
      "Керамическое покрытие идеально подходит для защиты кузова как нового, так и подержанного автомобиля. Студия BlackRock предлагает профессиональную защиту керамическим защитным покрытием высочайшего качества!",
    id: 4,
    link: "ceramic",
    descriptionFirst:
      "Керамическая защита делает лак более твердым и устойчивым к внешним воздействиям и микроцарапинам. Наиболее заметным эффектом после нанесения керамического покрытия является очень высокая гидрофобность, что делает автомобиль гораздо менее грязным. После нанесения эффект виден невооруженным глазом, а покрытие напоминает жидкое стекло, что придает защитным поверхностям глазурь и гладкость. В нашей студии мы используем высококачественные покрытия с гарантийным сроком от 12 месяцев до 6 лет.",
    workTime: "Cрок работ: от 1-го дня.",
  },

  {
    title: "воск",
    img: vosk,
    underTitle:
      "Восковое покрытие автомобиля создает хороший гидрофобный слой, благодаря которому на кузове автомобиля остается меньше грязи и пыли, а также предотвращает образование водного камня.",
    id: 5,
    link: "wax",
    descriptionFirst:
      "В ходе эксплуатации легкового автомобиля премиум-класса его кузов интенсивно подвергается механическому воздействию пыли, песка, асфальтовой крошки. Это ведет к образованию на поверхности лакокрасочного покрытия царапин, сколов. В дальнейшем сталь, лишенная защиты, начинает ржаветь и разрушаться. Рекомендуется наносить на кузов автомобиля специальные воски, чтобы защитить его поверхность. Кроме того, авто покрывается такими составами в том случае, когда подготавливается к консервации и длительному хранению в боксе или гараже.",
    workTime: "Cрок работ: от 1-го дня.",
  },

  {
    title: "антидождь",
    img: antiRain,
    underTitle:
      "Покрытие 'Антидождь' - это специальный состав, образующий на поверхности стекла защитный водоотталкивающий слой. Капли просто не успевают задерживаться на стекле!",
    id: 6,
    link: "anti-rain",
    descriptionFirst:
      "Антидождь создает отличный гидрофобный эффект, создает хорошую видимость на дорогах при любой погоде, обеспечивает безопасность водителя и пассажиров в салоне, также дает возможность управлять автомобилем без использования дворников, что продлевает их срок службы.",
    guaranty: "Срок службы покрытия обычно составляет от 3 до 6 месяцев.",
    workTime: "Срок работ: 1 день.",
  },

  {
    title: "шумоизоляция",
    img: soundIsolation,
    underTitle:
      "Опыт вождения на следующем уровне ждет Вас благодаря услуге шумоизоляции от авто-ателье BlackRock.. Мы поможем Вам сосредоточиться на дороге, минимизировав внешний шум, который может стать препятствием к вашему вниманию.",
    id: 7,
    link: "sound-isolation",
    descriptionFirst:
      "Шумоизоляция авто под ключ – комплексные работы, направленные на снижение уровня проникновения посторонних звуков в салон автомобиля. Такая процедура повышает комфорт вождения, степень безопасности авто и придает металлическим деталям дополнительные антикоррозийные свойства. Авто-ателье BlackRock выполнит шумоизоляцию любых марок и моделей автомобилей. Такая услуга особенно актуальна для любителей тишины или прослушивания музыки во время поездки, а также для семей с маленькими детьми. По завершению работ автовладелец сможет наслаждаться комфортной для него атмосферой в салоне, которую не будут нарушать внешние раздражающие факторы.",
    workTime: "Cрок работ: от 1-го дня",
    type: [
      "- Шумоизоляция пола",
      "- Шумоизоляция дверей",
      "- Шумоизоляция потолка",
      "- Шумоизоляция багажника",
      "- Шумоизоляция колёсных арок",
      "- Шумоизоляция моторного щита",
      "- Шумоизоизоляция торпедо",
      "- Антискрип",
    ],
  },

  {
    title: "химчистка",
    img: cleaning,
    underTitle:
      "Автомобили стали неотъемлемой частью нашей жизни. Мы используем их для повседневных дел, поездок на работу или в путешествиях. Для многих людей это место работы, или просто страсть или хобби. Поэтому помимо технического состояния автомобиля стоит позаботиться о салоне автомобиля, чтобы чувствовать себя комфортно.",
    id: 8,
    link: "cleaning",
    descriptionFirst:
      "BlackRock— это профессиональная студия детейлинга, которая предлагает комплексную химчистку салона вашего автомобиля. Как опытная команда, мы уделяем большое внимание деталям и стремимся предоставлять услуги высочайшего уровня. Мы выполним комплексную химчистку салона по приемлемым ценам. Наши специалисты быстро и эффективно справятся с самыми сильными загрязнениями при помощи профессиональных средств. По завершению работ клиент получает автомобиль с чистым ухоженным салоном, в котором будет приятно находиться и водителю, и пассажирам. Полная профессиональная химчистка салона автомобиля подразумевает по собой очистку сидений, потолка, пола, ковровых покрытий и резиновых ковриков. Для работы используются гипоаллергенные и экологичные моющие средства, моющий пылесос с насадками, пароочиститель.",
    price: "Стоимость от 200 BYN.",
    workTime: "Cрок работ: от  1-го дня.",
    material: "Материалы: Koch Chemie, Sonax, Grass, Shine Systems, Gyeon",
    type: [
      "- Химчистка пола",
      "- Химчистка багажника",
      "- Химчистка сидений",
      "- Химчистка ремней",
      "- Химчистка потолка",
      "- Химчистка руля",
      "- Химчистка дверей",
    ],
  },

  {
    title: "реставрация кожи",
    img: interiorRestoration,
    underTitle:
      "С течением времени кожа автомобилей становится все более и более поврежденной. Они теряют цвет и становятся все более восприимчивыми к дальнейшему разрушению. Вот почему мы также предлагаем услуги по реставрации кожи.",
    id: 9,
    link: "interior-restoration",
    descriptionFirst:
      "Салон автомобиля – визитная карточка автовладельца. При длительной эксплуатации любая вещь теряет свой товарный вид или приходит в негодность. Автоателье BlackRock выполнит восстановление кожаного салона и пластиковых поверхностей по доступной цене. Уже через пару часов заказчик сможет забрать свой автомобиль с полностью отреставрированным салоном, в котором будет приятно и комфортно находиться.",
    workTime: "Cрок работ: от 2 часов",
    price: "Стоимость от 100 BYN.",
    material: "Материалы: химия Letech",
  },

  {
    title: "перешив салона",
    img: alteringInterior,
    underTitle:
      "Прямой шов, аккуратная строчка, ровное натяжение - в BlackRock знают всё об этих терминах.",
    id: 10,
    link: "altering-interior",
    descriptionFirst:
      "Услуга, которая преобразит салон, придаст ему презентабельность и скроет недостатки. Обновленный интерьер машины будет многие годы радовать владельца, обеспечивать ему комфорт вождения и нахождения в авто. Виды работ: Перетяжка сидений автомобиля кожей бывает полной, включая водительское и пассажирское кресло, задний диван, а также локальной – только одно сидение либо его часть. Перешив сидений может быть выполнен по выкройке, соответствующей заводской обшивке, а также в соответствии с индивидуальными разработками. При необходимости возможно изменение анатомии кресел, добавление боковых поддержек, декоративных элементов. Полное обновление всех сидений обойдется клиенту от 1500 рублей, окончательная стоимость зависит от вида материала. Предоставляется гарантия.",
    workTime: "Cрок работ: от 5 дней",
    material: "Материалы: кожа Dakota, кожа Nappa, алькантара, экокожа",
    type: [
      "- Под расширенную заводскую комплектацию",
      "- По индивидуальному дизайну",
    ],
  },
];

export const inside = [
  {
    title: "химчистка",
    img: cleaning,
    underTitle:
      "Автомобили стали неотъемлемой частью нашей жизни. Мы используем их для повседневных дел, поездок на работу или в путешествиях. Для многих людей это место работы, или просто страсть или хобби. Поэтому помимо технического состояния автомобиля стоит позаботиться о салоне автомобиля, чтобы чувствовать себя комфортно.",
    id: 8,
    link: "cleaning",
    descriptionFirst:
      "BlackRock— это профессиональная студия детейлинга, которая предлагает комплексную химчистку салона вашего автомобиля. Как опытная команда, мы уделяем большое внимание деталям и стремимся предоставлять услуги высочайшего уровня. Мы выполним комплексную химчистку салона по приемлемым ценам. Наши специалисты быстро и эффективно справятся с самыми сильными загрязнениями при помощи профессиональных средств. По завершению работ клиент получает автомобиль с чистым ухоженным салоном, в котором будет приятно находиться и водителю, и пассажирам. Полная профессиональная химчистка салона автомобиля подразумевает по собой очистку сидений, потолка, пола, ковровых покрытий и резиновых ковриков. Для работы используются гипоаллергенные и экологичные моющие средства, моющий пылесос с насадками, пароочиститель.",
    price: "Стоимость от 200 BYN.",
    workTime: "Cрок работ: от  1-го дня.",
    material: "Материалы: Koch Chemie, Sonax, Grass, Shine Systems, Gyeon",
    type: [
      "- Химчистка пола",
      "- Химчистка багажника",
      "- Химчистка сидений",
      "- Химчистка ремней",
      "- Химчистка потолка",
      "- Химчистка руля",
      "- Химчистка дверей",
    ],
  },

  {
    title: "реставрация кожи",
    img: interiorRestoration,
    underTitle:
      "С течением времени кожа автомобилей становится все более и более поврежденной. Они теряют цвет и становятся все более восприимчивыми к дальнейшему разрушению. Вот почему мы также предлагаем услуги по реставрации кожи.",
    id: 9,
    link: "interior-restoration",
    descriptionFirst:
      "Салон автомобиля – визитная карточка автовладельца. При длительной эксплуатации любая вещь теряет свой товарный вид или приходит в негодность. Автоателье BlackRock выполнит восстановление кожаного салона и пластиковых поверхностей по доступной цене. Уже через пару часов заказчик сможет забрать свой автомобиль с полностью отреставрированным салоном, в котором будет приятно и комфортно находиться.",
    workTime: "Cрок работ: от 2 часов",
    price: "Стоимость от 100 BYN.",
    material: "Материалы: химия Letech",
  },

  {
    title: "перешив салона",
    img: alteringInterior,
    underTitle:
      "Прямой шов, аккуратная строчка, ровное натяжение - в BlackRock знают всё об этих терминах.",
    id: 10,
    link: "altering-interior",
    descriptionFirst:
      "Услуга, которая преобразит салон, придаст ему презентабельность и скроет недостатки. Обновленный интерьер машины будет многие годы радовать владельца, обеспечивать ему комфорт вождения и нахождения в авто. Виды работ: Перетяжка сидений автомобиля кожей бывает полной, включая водительское и пассажирское кресло, задний диван, а также локальной – только одно сидение либо его часть. Перешив сидений может быть выполнен по выкройке, соответствующей заводской обшивке, а также в соответствии с индивидуальными разработками. При необходимости возможно изменение анатомии кресел, добавление боковых поддержек, декоративных элементов. Полное обновление всех сидений обойдется клиенту от 1500 рублей, окончательная стоимость зависит от вида материала. Предоставляется гарантия.",
    workTime: "Cрок работ: от 5 дней",
    material: "Материалы: кожа Dakota, кожа Nappa, алькантара, экокожа",
    type: [
      "- Под расширенную заводскую комплектацию",
      "- По индивидуальному дизайну",
    ],
  },
];

export const outside = [
  {
    title: "оклейка антигравийной пленкой",
    img: pastingAntigravelFilm,
    underTitle:
      "Защитите кузов своего автомобиля прочной полиуретановой пленкой.",
    id: 1,
    link: "pasting-antigravel-film",
    descriptionFirst:
      "Автоателье BlackRock выполняет оклейку автомобилей полиуретановой пленкой для защиты автомобиля от гравия, царапин, внешних воздействий и мелких ДТП. Полиуретановая пленка толщиной 215 мкм обеспечит защиту лакокрасочного покрытия от повреждений и сохранит авто в первозданном виде. Автомобиль можно оклеить целиком, а также нанести пленку локально – на основные зоны риска.",
    descriptionSecond:
      "Мы используем только сертифицированные материалы. Работаем с автомобилями всех марок и классов.",
    workTime: "Cрок работ: от 3 дней",
    material: "Материалы: Llumar, Suntek, KPMF, Hexis, 3M, Oracal",
  },

  {
    title: "оклейка виниловой пленкой",
    img: vinylWrapping,
    underTitle:
      "Мы предлагаем комплексную оклейку виниловой пленкой автомобилей. Огромный выбор пленок гарантирует, что каждый клиент найдет что-то для себя. Наша студия в Минске изменит ваш автомобиль. Сделайте ставку на качество и опыт!",
    id: 2,
    link: "vinyl-wrapping",
    descriptionFirst:
      "Оклеить автомобиль виниловой пленкой – это не только способ изменить внешний вид вашего автомобиля, но и защитить его от механических повреждений. BlackRock предлагает широкий выбор цветной виниловой пленки, что позволяет кардинально изменить внешний вид вашего автомобиля. Вы можете выбрать матовую или глянцевую пленку, а также комбинировать разные цвета и текстуры. Наши специалисты имеют большой опыт в оклейке автомобилей и гарантируют высокое качество работы, а также мы используем только качественные материалы для оклейки Вашего авто.",
    descriptionSecond:
      "Виниловая пленка легко снимается, что позволяет вернуть кузову первоначальный вид в любой момент не оставляя следов на кузове.",
    workTime: "Cрок работ: от 5 дней",
    material: "Материалы: Llumar, Suntek, KPMF, Hexis, 3M, Oracal",
  },

  {
    title: "полировка",
    img: polishing,
    underTitle:
      "Со временем лак теряет свой первоначальный блеск, и после длительного периода эксплуатации образуются многочисленные царапины и голограммы. ",
    id: 3,
    link: "polishing",
    descriptionFirst:
      "Полировка автомобиля — одна из самых популярных и, возможно, более эффектных процедур по детализации. Полировка удаляет тысячи микроцарапин, крупных царапин и голограмм, образующихся во время мойки автомобиля и его эксплуатации. Это отличный способ придать лаку свежий вид, удалить пятна и потускнения. Кроме того, полировка автомобиля придает кузову стеклянный блеск, восстанавливает его первоначальный яркий цвет и даже усиливает его глубину. Но что важно, стоит поручить действия опытным специалистам. В противном случае процедура может привести к обратному результату. Неправильная коррекция лака может чрезмерно протирать слой, покрывающий кузов, что делает его слабым и подверженным воздействию неблагоприятных погодных условий, химических веществ, влаги и ультрафиолетовых лучей. Тем не менее, стоит отметить, что если процедура была проведена должным образом, то поверхность можно защитить керамическим покрытием или защитной полиуретановой пленкой.",
    price: "Стоимость работ от 200 BYN.",
    workTime: "Cрок работ: от 1-го дня",
    type: [
      "- профессиональная полировка кузова",
      "- покрытие керамикой",
      "- полировка вставок салона",
      " - полировка фар",
    ],
  },

  {
    title: "керамика",
    img: ceramic,
    underTitle:
      "Керамическое покрытие идеально подходит для защиты кузова как нового, так и подержанного автомобиля. Студия BlackRock предлагает профессиональную защиту керамическим защитным покрытием высочайшего качества!",
    id: 4,
    link: "ceramic",
    descriptionFirst:
      "Керамическая защита делает лак более твердым и устойчивым к внешним воздействиям и микроцарапинам. Наиболее заметным эффектом после нанесения керамического покрытия является очень высокая гидрофобность, что делает автомобиль гораздо менее грязным. После нанесения эффект виден невооруженным глазом, а покрытие напоминает жидкое стекло, что придает защитным поверхностям глазурь и гладкость. В нашей студии мы используем высококачественные покрытия с гарантийным сроком от 12 месяцев до 6 лет.",
    workTime: "Cрок работ: от 1-го дня.",
  },

  {
    title: "воск",
    img: vosk,
    underTitle:
      "Восковое покрытие автомобиля создает хороший гидрофобный слой, благодаря которому на кузове автомобиля остается меньше грязи и пыли, а также предотвращает образование водного камня.",
    id: 5,
    link: "wax",
    descriptionFirst:
      "В ходе эксплуатации легкового автомобиля премиум-класса его кузов интенсивно подвергается механическому воздействию пыли, песка, асфальтовой крошки. Это ведет к образованию на поверхности лакокрасочного покрытия царапин, сколов. В дальнейшем сталь, лишенная защиты, начинает ржаветь и разрушаться. Рекомендуется наносить на кузов автомобиля специальные воски, чтобы защитить его поверхность. Кроме того, авто покрывается такими составами в том случае, когда подготавливается к консервации и длительному хранению в боксе или гараже.",
    workTime: "Cрок работ: от 1-го дня.",
  },

  {
    title: "антидождь",
    img: antiRain,
    underTitle:
      "Покрытие 'Антидождь' - это специальный состав, образующий на поверхности стекла защитный водоотталкивающий слой. Капли просто не успевают задерживаться на стекле!",
    id: 6,
    link: "anti-rain",
    descriptionFirst:
      "Антидождь создает отличный гидрофобный эффект, создает хорошую видимость на дорогах при любой погоде, обеспечивает безопасность водителя и пассажиров в салоне, также дает возможность управлять автомобилем без использования дворников, что продлевает их срок службы.",
    guaranty: "Срок службы покрытия обычно составляет от 3 до 6 месяцев.",
    workTime: "Срок работ: 1 день.",
  },

  {
    title: "шумоизоляция",
    img: soundIsolation,
    underTitle:
      "Опыт вождения на следующем уровне ждет Вас благодаря услуге шумоизоляции от авто-ателье BlackRock.. Мы поможем Вам сосредоточиться на дороге, минимизировав внешний шум, который может стать препятствием к вашему вниманию.",
    id: 7,
    link: "sound-isolation",
    descriptionFirst:
      "Шумоизоляция авто под ключ – комплексные работы, направленные на снижение уровня проникновения посторонних звуков в салон автомобиля. Такая процедура повышает комфорт вождения, степень безопасности авто и придает металлическим деталям дополнительные антикоррозийные свойства. Авто-ателье BlackRock выполнит шумоизоляцию любых марок и моделей автомобилей. Такая услуга особенно актуальна для любителей тишины или прослушивания музыки во время поездки, а также для семей с маленькими детьми. По завершению работ автовладелец сможет наслаждаться комфортной для него атмосферой в салоне, которую не будут нарушать внешние раздражающие факторы.",
    workTime: "Cрок работ: от 1-го дня",
    type: [
      "- Шумоизоляция пола",
      "- Шумоизоляция дверей",
      "- Шумоизоляция потолка",
      "- Шумоизоляция багажника",
      "- Шумоизоляция колёсных арок",
      "- Шумоизоляция моторного щита",
      "- Шумоизоизоляция торпедо",
      "- Антискрип",
    ],
  },
];
