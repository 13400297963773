import "./PortfolioSmall.css";
import Slider from "react-slick";
import firstImg from "../../assets/portfolio/firstImg.png";
import secondImg from "../../assets/portfolio/secondImg.png";
import thirdImg from "../../assets/portfolio/thirdImg.png";
import fourthImg from "../../assets/portfolio/fourthImg.png";
import fifthImg from "../../assets/portfolio/fifthImg.png";
import sixthImg from "../../assets/portfolio/sixthImg.png";
import seventhImg from "../../assets/portfolio/seventhImg.png";
import { useMediaQuery } from "react-responsive";

const coruselImg = [
  firstImg,
  secondImg,
  thirdImg,
  fourthImg,
  fifthImg,
  sixthImg,
  seventhImg,
];

const PortfolioSmall = ({ portfolioRef }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isDesktopOrLaptop ? 3 : 1,
    slidesToScroll: 1,
  };

  return (
    <div className="portfolio_container_small" ref={portfolioRef}>
      <span className="portfolio_title_small">портфолио</span>
      <div className="slider_container_small">
        <Slider {...settings}>
          <img
            src={coruselImg[0]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[1]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[2]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[3]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[4]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[5]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
          <img
            src={coruselImg[6]}
            alt="img"
            loading="lazy"
            // effect="blur"
          />
        </Slider>
      </div>
    </div>
  );
};

export default PortfolioSmall;
