import { useEffect, useState } from "react";
import "./ReviewBig.css";
import { useMediaQuery } from "react-responsive";
import reviewFirst from "../../assets/reviews/first.png";
import reviewSecond from "../../assets/reviews/second.png";
import reviewThird from "../../assets/reviews/third.png";
import reviewFourth from "../../assets/reviews/fourth.png";
import leftArrowBig from "../../assets/leftArrowBig.png";
import rightArrowBig from "../../assets/rightArrowBig.png";
import instagramReview from "../../assets/instagramReview.png";

const reviews = [
  {
    img: reviewFirst,
    title: "yachnik.a",
    desc: "“Очень давно хотел черный потолок, сделали все четко, просто красота!”",
  },
  {
    img: reviewSecond,
    title: "solmairs_01",
    desc: "“Спасибо ребятам что вернули салон к жизни!”",
  },
  {
    img: reviewThird,
    title: "v.barada",
    desc: "“Спасибо за оперативный ремонт, восстановили боковую поддержку и сделали частичную покраску!”",
  },
  {
    img: reviewFourth,
    title: "gorobchenk0",
    desc: "“Перешили потолок, стойки, перекрасили и подремонтировали пластик на потолке, сделали ремон сиденья. Это прям топ, спасибо!”",
  },
  {
    img: instagramReview,
    title: "отзывы",
    desc: "“Больше отзывов в нашем инстаграме”",
  },
];

const ReviewBig = () => {
  const [center, setCenter] = useState(0);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const isMiddleScreen = useMediaQuery({
    query: "(max-width: 1400px)",
  });

  useEffect(() => {
    if (prev.animation) {
      setTimeout(() => {
        setPrev(false);
        setCenter(prev.index);
      }, 500);
    }
    if (next.animation) {
      setTimeout(() => {
        setNext(false);
        setCenter(next.index);
      }, 500);
    }
  }, [prev, next]);

  const onPrev = () => {
    if (center === 0 && !prev) {
      setPrev({ animation: true, index: 4 });
    } else {
      setPrev({ animation: true, index: center - 1 });
    }
  };

  const onNext = () => {
    if (center === 4) {
      setNext({ animation: true, index: 0 });
    } else {
      setNext({ animation: true, index: center + 1 });
    }
  };
  return (
    <div className="review_container">
      <span className="portfolio_title">ОТЗЫВЫ</span>
      <div className="corusel_container_review">
        <div
          onClick={onPrev}
          className={
            prev.animation
              ? "animated_prev_review"
              : next.animation
              ? "animated_prev_on_next_review"
              : "prev_review"
          }
        >
          <img
            src={center === 0 ? reviews[4].img : reviews[center - 1].img}
            alt="img"
            className={
              prev.animation || next.animation
                ? "img_corusel_animated"
                : "img_corusel_prev_review"
            }
            // effect="blur"
            loading="lazy"
          />
          <p className="review_title">
            {center === 0 ? reviews[4].title : reviews[center - 1].title}
          </p>
          <div
            className={
              isMiddleScreen
                ? "desc_review_container_middle"
                : "desc_review_container"
            }
          >
            <span className="review_desc">
              {center === 0 ? reviews[4].desc : reviews[center - 1].desc}
            </span>
          </div>
        </div>
        <div onClick={onPrev}>
          <img
            src={leftArrowBig}
            alt="img"
            className="left_arrow_review_big"
            loading="lazy"
            // effect="blur"
          />
        </div>
        <div
          className={
            prev.animation
              ? "animated_center_prev_review"
              : next.animation
              ? "animated_center_prev_on_next_review"
              : "current_review"
          }
        >
          <img
            src={reviews[center].img}
            alt="img"
            className={
              prev.animation || next.animation
                ? "img_corusel_animated"
                : "img_corusel_review"
            }
            // effect="blur"
            loading="lazy"
          />
          <p className="review_title">{reviews[center].title}</p>
          <div
            className={
              isMiddleScreen
                ? "desc_review_container_middle"
                : "desc_review_container"
            }
          >
            <a
              className="review_desc"
              href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
            >
              {reviews[center].desc}
            </a>
          </div>
        </div>
        <div onClick={onNext}>
          <img
            src={rightArrowBig}
            alt="img"
            className="right_arrow_review_big"
            // effect="blur"
            loading="lazy"
          />
        </div>

        <div
          onClick={onNext}
          className={
            prev.animation
              ? "animated_center_on_prev_next_review"
              : next.animation
              ? "animated_next_on_next_review"
              : "next_review"
          }
        >
          <img
            src={center === 4 ? reviews[0].img : reviews[center + 1].img}
            className={
              prev.animation || next.animation
                ? "img_corusel_animated"
                : "img_corusel_next_review"
            }
            // effect="blur"
            alt="button_next"
            loading="lazy"
          />
          <p className="review_title">
            {center === 4 ? reviews[0].title : reviews[center + 1].title}
          </p>
          <div
            className={
              isMiddleScreen
                ? "desc_review_container_middle"
                : "desc_review_container"
            }
          >
            <span className="review_desc">
              {center === 4 ? reviews[0].desc : reviews[center + 1].desc}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewBig;
