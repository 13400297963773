import React, { useEffect, useRef } from "react";
import burgerMenu from "../../assets/burgerMenu.svg";
import "./ServiceType.css";
import { useMediaQuery } from "react-responsive";
import { useParams, useNavigate, Link } from "react-router-dom";
import { all, outside, inside } from "./servicesList";
import FoorterBig from "../../components/foorterBig/FoorterBig";
import FoorterSmall from "../../components/foorterSmall/FoorterSmall";
import Header from "../../components/header/Header";
import Driver from "../../components/driver/Driver";

const ServiceType = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const contactsRef = useRef();
  const [isDriverOpen, setIsDriverOpen] = React.useState(false);

  useEffect(() => {
    if (isDriverOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isDriverOpen]);

  const openDriverMenu = () => {
    console.log("fffff");
    setIsDriverOpen((state) => !state);
  };

  const isSmallPhone = useMediaQuery({
    query: "(min-width: 400px)",
  });
  const navigate = useNavigate();

  const onService = () => {
    setIsDriverOpen(false);
    navigate("/", { state: true });
  };

  const onContact = () => {
    setIsDriverOpen(false);
    contactsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onMain = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1000) {
      navigate("/", { replace: true, state: true });
    }
  }, [isDesktopOrLaptop, navigate]);

  let { title } = useParams();
  const screenTitle =
    title === "all-services"
      ? "все услуги:"
      : title === "car-body"
      ? "кузов:"
      : "салон:";
  const renderList =
    title === "all-services" ? all : title === "car-body" ? outside : inside;
  return (
    <div
      className="mainContauner"
      onClick={() => {
        if (isDriverOpen) {
          setIsDriverOpen(false);
        }
      }}
    >
      {isDesktopOrLaptop ? (
        <div className="header_container">
          <Header />
        </div>
      ) : (
        <div className="header_container_small">
          <p className="header_title" onClick={onMain}>
            black rock custom
          </p>
          <img
            src={burgerMenu}
            alt="burgerMenu"
            onClick={openDriverMenu}
            // effect="blur"
            // loading="lazy"
          />
        </div>
      )}
      <Driver
        isDriverOpen={isDriverOpen}
        scrollToServices={onService}
        onContact={onContact}
      />

      <div className="container_type">
        <span className="services_title_small">{screenTitle}</span>
        {renderList.map((it) => (
          <div
            onClick={() => {
              if (isDriverOpen) {
                setIsDriverOpen(false);
              }
            }}
          >
            <Link to={isDriverOpen ? "" : `/service/${it.link}`} key={it.id}>
              <div className="service_img_container_small">
                <img
                  src={it.img}
                  className="services_img_small"
                  alt={"services_img_small"}
                  // effect="blur"
                  // loading="lazy"
                />
                <span
                  className={
                    isSmallPhone || it.title.length < 20
                      ? "services_text_small"
                      : "services_text_small_phone"
                  }
                >
                  {it.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="footer_container_type">
        {isDesktopOrLaptop ? (
          <FoorterBig />
        ) : (
          <FoorterSmall contactsRef={contactsRef} />
        )}
      </div>
    </div>
  );
};

export default ServiceType;
