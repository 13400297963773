import "./Driver.css";
import LogoImage from "../../assets/logo.png";
import Button from "../button/Button";
import phoneIcon from "../../assets/phoneIcon.png";
import instagramIconContact from "../../assets/instagramIconContact.png";
import { useNavigate } from "react-router-dom";
import tiktokIcon from "../../assets/tiktok.png";

const Driver = ({
  isDriverOpen,
  isMain,
  scrollToServices,
  onContact,
  isPriceList,
  openModal,
}) => {
  const navigate = useNavigate();

  const onMain = () => {
    navigate("/");
  };

  const onPriceList = () => {
    navigate("/price-list");
  };
  return (
    <div className={isDriverOpen ? "driver_menu_animated" : "driver_menu"}>
      <div className="driver_menu_logo">
        <img
          src={LogoImage}
          alt="backgroundImage"
          className="logo_small"
          // effect="blur"
          loading="lazy"
        />
      </div>
      <div className="driver_menu_text_container">
        <p
          className={isMain ? "driver_menu_item_active" : "driver_menu_item"}
          onClick={onMain}
        >
          Главная
        </p>
        <p className="driver_menu_item" onClick={scrollToServices}>
          Услуги
        </p>
        <p
          className={
            isPriceList ? "driver_menu_item_active" : "driver_menu_item"
          }
          onClick={onPriceList}
        >
          Прайс-лист
        </p>
        {/* <p className="driver_menu_item" onClick={scrollToPortfolio}>
          Портфолио
        </p> */}
        <p className="driver_menu_item" onClick={onContact}>
          Контакты
        </p>
      </div>
      {isPriceList ? null : (
        <div className="button_container" onClick={openModal}>
          <Button title={"КОНСУЛЬТАЦИЯ"} />
        </div>
      )}
      <div className="contact_container">
        <div className="contact_item">
          <img
            src={phoneIcon}
            alt="phoneIcon"
            className="phone_icon"
            // effect="blur"
            loading="lazy"
          />
          <a
            className="driver_menu_item_contact"
            href="tel:+375336889393"
            target="_blank"
            rel="noreferrer"
          >
            +375 (33) 688-93-93
          </a>
        </div>
        <div className="contact_item">
          <img
            src={instagramIconContact}
            alt="instagramIconContact"
            className="phone_icon"
            // effect="blur"
            loading="lazy"
          />
          <a
            className="driver_menu_item_instagram"
            href="https://instagram.com/blackrock.custom?igshid=MXA3YzI4c3BqZDY3cQ=="
            target="_blank"
            rel="noreferrer"
          >
            instagram
          </a>
        </div>
        <div className="contact_item">
          <img
            src={tiktokIcon}
            alt="instagramIconContact"
            className="tiktok_icon_draver"
            // effect="blur"
            loading="lazy"
          />
          <a
            className="driver_menu_item_instagram"
            href="https://www.tiktok.com/@blackrock.custom?_t=8iyUi1grVi9&_r=1"
            target="_blank"
            rel="noreferrer"
          >
            Tik Tok
          </a>
        </div>
      </div>
    </div>
  );
};

export default Driver;
