import "./SecondBlockSmall.css";
import firstImageSmall from "../../assets/secondBlock/firstImageSmall.png";
import secondImageSmall from "../../assets/secondBlock/secondImageSmall.png";

const SecondBlogSmall = () => {
  return (
    <div className="second_block_small">
      <div className="second_block_img_container_first">
        <p className="second_block_title_small">
          <span className="second_block_title_yellow">black rock custom</span> -
          это не просто студия детейлинга, а спа-салон для вашего авто.
        </p>
        <img
          src={firstImageSmall}
          className="second_block_first_image_small"
          alt={"img descript"}
          // effect="blur"
          loading="lazy"
        />
      </div>
      <p className="second_block_text_small">
        Наша цель - это забота о внешнем виде автомобилей, потому что они
        являются важным элементом имиджа их владельцев.
      </p>
      <img
        src={secondImageSmall}
        className="second_block_second_image_small"
        alt={"img descript"}
        // effect="blur"
        loading="lazy"
      />
    </div>
  );
};

export default SecondBlogSmall;
