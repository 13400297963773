import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/button/Button";
import burgerMenu from "../../assets/burgerMenu.svg";

import "./Service.css";
import { useMediaQuery } from "react-responsive";
import Header from "../../components/header/Header";
import FoorterBig from "../../components/foorterBig/FoorterBig";
import FoorterSmall from "../../components/foorterSmall/FoorterSmall";
import { useParams } from "react-router-dom";
import { all } from "./servicesList";
import Driver from "../../components/driver/Driver";
import { useNavigate } from "react-router-dom";
import ModalBig from "../../components/modalBig/ModalBig";
import ModalSmall from "../../components/modalSmall/ModalSmall";
import emailjs from "@emailjs/browser";

const Service = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  let { title } = useParams();
  const [serviceInfo] = all.filter((it) => it.link === title);
  const form = useRef();

  const [isDriverOpen, setIsDriverOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState();
  const [nameError, setNameError] = useState(false);

  const [number, setNumber] = useState();
  const [numberError, setNumberError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const contactsRef = useRef();

  const navigate = useNavigate();

  const scrollToContact = () => {
    if (isDesktopOrLaptop) {
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setIsDriverOpen(false);
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMain = () => {
    navigate("/");
  };

  const onOpenService = () => {
    if (isDesktopOrLaptop) {
      navigate("/", { state: true });
    } else {
      setIsDriverOpen(false);
      navigate("/", { state: true });
    }
    // contactsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isDriverOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isDriverOpen]);

  const openDriverMenu = () => {
    console.log("fffff");
    setIsDriverOpen((state) => !state);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setIsloading(false);
    setIsSuccess(false);
    setIsError(false);
    setIsOpen(false);
    setNumberError(false);
    setNameError(false);
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeNumber = (event) => {
    setNumber(event);
  };

  const openModal = () => {
    if (isDesktopOrLaptop) {
      setIsOpen(true);
    } else {
      setIsDriverOpen(false);
      setIsOpen(true);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name) {
      setNameError(true);
    } else if (number?.length !== 12) {
      setNumberError(true);
    } else if (name && number?.length === 12) {
      setIsloading(true);

      emailjs
        .sendForm(
          "service_b6j173o",
          "template_c01hcyf",
          form.current,
          "Ne5wd-VaeTIsJTuDX"
        )
        .then(
          (result) => {
            setIsloading(false);
            setIsSuccess(true);
            console.log(result.text);
          },
          (error) => {
            setIsloading(false);
            setIsError(true);
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div
      className="mainContauner"
      onClick={() => {
        if (isDriverOpen) {
          setIsDriverOpen(false);
        }
      }}
    >
      {isDesktopOrLaptop ? (
        <div className="header_container">
          <Header
            onServices={onOpenService}
            onMain={onMain}
            onServiceList={() => {}}
            onContact={scrollToContact}
            isPriceList={true}
          />
        </div>
      ) : (
        <div className="header_container_small">
          <p className="header_title">black rock custom</p>
          <img
            src={burgerMenu}
            alt="burgerMenu"
            onClick={openDriverMenu}
            // effect="blur"
            // loading="lazy"
          />
        </div>
      )}
      <Driver
        isDriverOpen={isDriverOpen}
        scrollToServices={onOpenService}
        onContact={scrollToContact}
        openModal={openModal}
      />

      <p
        className={
          isDesktopOrLaptop ? "service_desc_title" : "service_desc_title_small"
        }
      >
        {serviceInfo.title}
      </p>
      <div
        className="service_desc_img_container"
        onClick={() => {
          if (isDriverOpen) {
            setIsDriverOpen(false);
          }
        }}
      >
        <img
          src={serviceInfo.img}
          className="service_desc_img"
          alt="service_img"
          // effect="blur"
          // loading="lazy"
        />
      </div>
      <div
        onClick={() => {
          if (isDriverOpen) {
            setIsDriverOpen(false);
          }
        }}
      >
        <p
          className={
            isDesktopOrLaptop
              ? "service_desc_under_title"
              : "service_desc_under_title_small"
          }
        >
          {serviceInfo.underTitle}
        </p>
        <p
          className={
            isDesktopOrLaptop
              ? "service_desc_description"
              : "service_desc_description_small"
          }
        >
          {serviceInfo.descriptionFirst}
        </p>
        {serviceInfo.descriptionSecond ? (
          <p
            className={
              isDesktopOrLaptop
                ? "service_desc_description"
                : "service_desc_description_small"
            }
          >
            {serviceInfo.descriptionSecond}
          </p>
        ) : null}
        {serviceInfo.price ? (
          <p
            className={
              isDesktopOrLaptop
                ? "service_desc_price"
                : "service_desc_price_small"
            }
          >
            {serviceInfo.price}
          </p>
        ) : null}
        {serviceInfo.guaranty ? (
          <p
            className={
              isDesktopOrLaptop
                ? "service_desc_description"
                : "service_desc_description_small"
            }
          >
            {serviceInfo.guaranty}
          </p>
        ) : null}
        <p
          className={
            isDesktopOrLaptop
              ? "service_desc_description"
              : "service_desc_description_small"
          }
        >
          {serviceInfo.workTime}
        </p>
        <p
          className={
            isDesktopOrLaptop
              ? "service_desc_material"
              : "service_desc_material_small"
          }
        >
          {serviceInfo.material}
        </p>
        {serviceInfo.type ? (
          <div className="service_type_container">
            {serviceInfo.type.map((it) => (
              <p
                className={
                  isDesktopOrLaptop
                    ? "service_desc_type"
                    : "service_desc_type_small"
                }
              >
                {it}
              </p>
            ))}
          </div>
        ) : null}
        <p
          className={
            isDesktopOrLaptop ? "service_desc_ps" : "service_desc_ps_small"
          }
        >
          P.S. - предварительная запись обязательна!
        </p>
        <div
          className={
            isDesktopOrLaptop
              ? "service_btn_container"
              : "service_btn_container_small"
          }
        >
          <Button title="ЗАПИСАТЬСЯ" onClick={openModal} />
        </div>
      </div>
      {isDesktopOrLaptop ? (
        <FoorterBig contactsRef={contactsRef} />
      ) : (
        <FoorterSmall contactsRef={contactsRef} />
      )}
      {isDesktopOrLaptop ? (
        <ModalBig
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          form={form}
          onChangeName={onChangeName}
          onChangeNumber={onChangeNumber}
          sendEmail={sendEmail}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          nameError={nameError}
          numberError={numberError}
        />
      ) : (
        <ModalSmall
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          form={form}
          onChangeName={onChangeName}
          onChangeNumber={onChangeNumber}
          sendEmail={sendEmail}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          nameError={nameError}
          numberError={numberError}
        />
      )}
    </div>
  );
};

export default Service;
