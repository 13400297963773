import React from "react";
import { createHashRouter } from "react-router-dom";
import Main from "../screen/main/Main";
import Service from "../screen/service/Service";
import ServiceType from "../screen/serviceType/ServiceType";
import PriceList from "../screen/priceList/PriceList";

export const router = createHashRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/service/:title",
    element: <Service />,
  },
  {
    path: "/service-type/:title",
    element: <ServiceType />,
  },
  {
    path: "/price-list",
    element: <PriceList />,
  },
]);
