import "./Button.css";
import { useMediaQuery } from "react-responsive";

const Button = ({ title, onClick }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  return (
    <div
      className={isDesktopOrLaptop ? "button" : "button_small"}
      onClick={onClick}
    >
      <p className={isDesktopOrLaptop ? "button_title" : "button_title_small"}>
        {title}
      </p>
    </div>
  );
};

export default Button;
