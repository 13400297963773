import "./FoorterSmall.css";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import footerLogo from "../../assets/footerLogo.png";
import mapImg from "../../assets/mapImg.png";
import phoneIconWhite from "../../assets/phoneIconWhite.png";
import instagramIcon from "../../assets/instagramIcon.png";
import tiktokIcon from "../../assets/tiktok.png";

const FoorterSmall = ({ contactsRef }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCoCkEl0L3FilimbHGywQerFAcUf1MKBwY",
  });

  return (
    <div ref={contactsRef}>
      <span className="footer_title_small">КОНТАКТЫ</span>
      <div className="footer_img_container_small">
        <img
          src={footerLogo}
          className="footer_img_small"
          alt="footer_img_small"
          // effect="blur"
          loading="lazy"
        />
        <div className="footer_text_container_small">
          <span className="footer_text_adress">
            г. Минск Меньковский тракт 2 авторынок малиновка
          </span>
          <span className="footer_text">Пн - Пт с 11:00 до 20:00 </span>
          <span className="footer_text_weekend">Сб/Вс - выходной</span>
          <div className="footer_contact">
            <img
              src={phoneIconWhite}
              alt="phoneIcon"
              className="phone_icon_footer"
              // effect="blur"
              loading="lazy"
            />
            <a
              className="footer_text_number_small"
              href="tel:+375336889393"
              target="_blank"
              rel="noreferrer"
            >
              +375 (33) 688-93-93
            </a>
          </div>
          <div className="footer_contact">
            <img
              src={instagramIcon}
              alt="instagramIcon"
              className="phone_icon_footer"
              // effect="blur"
              loading="lazy"
            />
            <a
              className="footer_text_number_small"
              href="https://instagram.com/blackrock.custom?igshid=MXA3YzI4c3BqZDY3cQ=="
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </div>
          <div className="footer_contact">
            <img
              src={tiktokIcon}
              alt="tiktokIcon"
              className="tiktok_icon"
              // effect="blur"
              loading="lazy"
            />
            <a
              className="footer_text_number_small"
              href="https://www.tiktok.com/@blackrock.custom?_t=8iyUi1grVi9&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              Tik Tok
            </a>
          </div>
        </div>
      </div>
      <div className="footer_map_container_small">
        <div className="footer_title_map_container_small">
          <span>
            <a
              className="footer_title_map_small"
              href="https://yandex.ru/navi/org/76720367635"
              target="_blank"
              rel="noreferrer"
            >
              Как нас найти
            </a>
          </span>
        </div>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: "95%",
              height: "300px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
            center={{ lat: 53.859278, lng: 27.417026 }}
            zoom={18}
          >
            <Marker position={{ lat: 53.859278, lng: 27.417026 }} />
          </GoogleMap>
        ) : (
          <img
            src={mapImg}
            className="img_map_small"
            alt="img_map_small"
            // effect="blur"
            loading="lazy"
          />
        )}
      </div>
      <div className="footer_container_menu_small">
        <p className="footer_menu_option_small">Услуги</p>
        <p className="footer_menu_option_small">Портфолио</p>
      </div>
      <div className="footer_bussines_container">
        <span className="footer_bussines_info">ЧУП «АльфаТрейлер сервис»</span>
        <span className="footer_bussines_info">
          г. Минск, ул. Тимирязева, д72
        </span>
        <span className="footer_bussines_info">УНП 193627582</span>
      </div>
    </div>
  );
};

export default FoorterSmall;
