import "./ServicesSmall.css";
import { services } from "./servicesListSmall";
import { Link } from "react-router-dom";

const ServicesSmall = ({ servicesRef }) => {
  return (
    <div ref={servicesRef}>
      <span className="services_title_small">Услуги:</span>
      {services.map((it) => (
        <Link to={`/service-type/${it.link}`} key={it.id}>
          <div className="service_img_container_small">
            <img
              src={it.img}
              className="services_img_small"
              alt="services_img_small"
              // effect="blur"
              loading="lazy"
            />
            <span className="services_text_small">{it.title}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ServicesSmall;
