import "./ReviewSmall.css";
import reviewFirst from "../../assets/reviews/first.png";
import reviewSecond from "../../assets/reviews/second.png";
import reviewThird from "../../assets/reviews/third.png";
import reviewFourth from "../../assets/reviews/fourth.png";
import instagramReview from "../../assets/instagramReview.png";
import Slider from "react-slick";

const reviews = [
  {
    img: reviewFirst,
    title: "yachnik.a",
    desc: "“Очень давно хотел черный потолок, сделали все четко, просто красота!”",
  },
  {
    img: reviewSecond,
    title: "solmairs_01",
    desc: "“Спасибо ребятам что вернули салон к жизни!”",
  },
  {
    img: reviewThird,
    title: "v.barada",
    desc: "“Спасибо за оперативный ремонт, восстановили боковую поддержку и сделали частичную покраску!”",
  },
  {
    img: reviewFourth,
    title: "gorobchenk0",
    desc: "“Перешили потолок, стойки, перекрасили и подремонтировали пластик на потолке, сделали ремон сиденья. Это прям топ, спасибо!”",
  },
  {
    img: instagramReview,
    title: "отзывы",
    desc: "“Больше отзывов в нашем инстаграме”",
  },
];

const ReviewSmall = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  };
  return (
    <div className="portfolio_container_small">
      <span className="portfolio_title_small">отзывы</span>
      <div className="slider_container_small">
        <Slider {...settings}>
          <div className="review_img_container">
            <div className="review_img_padding">
              <div className="padding" />
              <img
                src={reviews[0].img}
                className="review_img_small"
                alt="img"
                // effect="blur"
                // loading="lazy"
              />
              <div className="padding" />
            </div>
            <p className="review_title">{reviews[0].title}</p>
            <div className="review_img_padding">
              <a
                className="review_desc_small"
                href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                {reviews[0].desc}
              </a>
            </div>
          </div>
          <div className="review_img_container">
            <div className="review_img_padding">
              <div className="padding" />
              <img
                src={reviews[1].img}
                className="review_img_small"
                alt="review_img_small"
                // effect="blur"
                loading="lazy"
              />
              <div className="padding" />
            </div>
            <p className="review_title">{reviews[1].title}</p>
            <div className="review_img_padding">
              <a
                className="review_desc_small"
                href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                {reviews[1].desc}
              </a>
            </div>
          </div>

          <div className="review_img_container">
            <div className="review_img_padding">
              <div className="padding" />
              <img
                src={reviews[2].img}
                className="review_img_small"
                alt="review_img_small"
                // effect="blur"
                loading="lazy"
              />
              <div className="padding" />
            </div>
            <p className="review_title">{reviews[2].title}</p>
            <div className="review_img_padding">
              <a
                className="review_desc_small"
                href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                {reviews[2].desc}
              </a>
            </div>
          </div>

          <div className="review_img_container">
            <div className="review_img_padding">
              <div className="padding" />
              <img
                src={reviews[3].img}
                className="review_img_small"
                alt="review_img_small"
                // effect="blur"
                loading="lazy"
              />
              <div className="padding" />
            </div>
            <p className="review_title">{reviews[3].title}</p>
            <div className="review_img_padding">
              <a
                className="review_desc_small"
                href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                {reviews[3].desc}
              </a>
            </div>
          </div>
          <div className="review_img_container">
            <div className="review_img_padding">
              <div className="padding" />
              <img
                src={reviews[4].img}
                className="review_img_small"
                alt="review_img_small"
                // effect="blur"
                loading="lazy"
              />
              <div className="padding" />
            </div>
            <p className="review_title">{reviews[4].title}</p>
            <div className="review_img_padding">
              <a
                className="review_desc_small"
                href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTQ3NzQ0NzIxMDg4Mzg0?story_media_id=2887966942489033871&igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                {reviews[4].desc}
              </a>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};
export default ReviewSmall;
