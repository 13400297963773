import "./Header.css";
import LogoImage from "../../assets/logo.png";
import phoneIcon from "../../assets/phoneIcon.png";
import { Link } from "react-router-dom";

const Header = ({ onServices, isPriceList, onContact, onMain }) => {
  return (
    <nav className="header">
      <ul className="menu_options">
        <li className="menu_item" onClick={onServices}>
          Услуги
        </li>
        <Link to={`/price-list`} className="remove_underline">
          <li className={isPriceList ? "menu_item_active" : "menu_item"}>
            Прайс-лист
          </li>
        </Link>

        <li className="menu_item" onClick={onContact}>
          Контакты
        </li>
      </ul>
      <img
        src={LogoImage}
        alt="backgroundImage"
        className="logo"
        onClick={onMain}
        // effect="blur"
        loading="lazy"
      />
      <div className="header_phone_container">
        <img
          src={phoneIcon}
          alt="phoneIcon"
          className="phone_icon_header"
          // effect="blur"
          loading="lazy"
        />
        <a
          className="phone"
          href="tel:+375336889393"
          target="_blank"
          rel="noreferrer"
        >
          +375 (33) 688-93-93
        </a>
      </div>
    </nav>
  );
};

export default Header;
