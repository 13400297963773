import React, { useEffect, useRef } from "react";
import "./PriceList.css";
import ServiceListBG from "../../assets/serviceListBG.png";
import Header from "../../components/header/Header";
import { useMediaQuery } from "react-responsive";
import burgerMenu from "../../assets/burgerMenu.svg";
import LogoImage from "../../assets/logo.png";
import Driver from "../../components/driver/Driver";
import {
  soundInsulation,
  interiorReupholstery,
  soundInsulationSecond,
  soundInsulationThird,
  interiorRestoration,
  dryCleaning,
  polishing,
} from "./PriceListItems";
import showMore from "../../assets/showMore.png";
import FoorterBig from "../../components/foorterBig/FoorterBig";
import FoorterSmall from "../../components/foorterSmall/FoorterSmall";
import { useNavigate } from "react-router-dom";

const PriceList = () => {
  const [isDriverOpen, setIsDriverOpen] = React.useState(false);
  const [indexPrice, setIndexPrice] = React.useState(null);
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });

  const contactsRef = useRef();

  const scrollToContact = () => {
    if (isDesktopOrLaptop) {
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setIsDriverOpen(false);
      contactsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!isDesktopOrLaptop) {
      setIsDriverOpen(false);
    }
  }, [isDesktopOrLaptop]);

  const onOpenService = () => {
    if (isDesktopOrLaptop) {
      navigate("/", { state: true });
    }
    setIsDriverOpen(false);
    navigate("/", { state: true });
  };

  useEffect(() => {
    if (isDriverOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isDriverOpen]);

  const onMain = () => {
    navigate("/");
  };

  const openDriverMenu = () => {
    setIsDriverOpen((state) => !state);
  };

  const showAllPrice = (index) => {
    if (indexPrice === index) {
      setIndexPrice(null);
    } else {
      setIndexPrice(index);
    }
  };

  const hideAllPrice = () => {
    if (indexPrice) {
      setIndexPrice(null);
    }

    if (isDriverOpen) {
      setIsDriverOpen(false);
    }
  };

  return (
    <div className="container_price_list" onClick={hideAllPrice}>
      <img
        src={ServiceListBG}
        className="BG_image"
        alt="bg_img"
        onClick={hideAllPrice}
        // effect="blur"
        // loading="lazy"
      />
      {isDesktopOrLaptop ? (
        <div className="header_container">
          <Header
            onServices={onOpenService}
            onMain={onMain}
            onServiceList={() => {}}
            onContact={scrollToContact}
            isPriceList={true}
          />
        </div>
      ) : (
        <div className="header_container_small">
          <div style={{ width: "30px" }} />
          {/* <p className="header_title">black rock custom</p> */}
          <img
            src={LogoImage}
            alt="backgroundImage"
            className="logo_small"
            onClick={onMain}
            // effect="blur"
            // loading="lazy"
          />

          <img
            src={burgerMenu}
            alt="burgerMenu"
            onClick={openDriverMenu}
            // effect="blur"
            // loading="lazy"
          />
        </div>
      )}
      <Driver
        isDriverOpen={isDriverOpen}
        onContact={scrollToContact}
        scrollToServices={onOpenService}
        isPriceList={true}
      />
      <h1
        className={
          isDesktopOrLaptop ? "price_list_title_big" : "price_list_title_small"
        }
      >
        Прайс-лист на услуги
      </h1>

      <div
        className={
          isDesktopOrLaptop
            ? "sound_isolation_title_container"
            : "sound_isolation_title_container_small"
        }
      >
        <span
          className={
            isDesktopOrLaptop
              ? "sound_isolation_title"
              : "sound_isolation_title_small"
          }
        >
          {soundInsulation.title}
        </span>
      </div>
      {soundInsulation.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}

      <div
        className={
          isDesktopOrLaptop
            ? "sound_isolation_title_container"
            : "sound_isolation_title_container_small"
        }
      >
        <span
          className={
            isDesktopOrLaptop
              ? "sound_isolation_title"
              : "sound_isolation_title_small"
          }
        >
          {interiorReupholstery.title}
        </span>
      </div>
      {interiorReupholstery.items.map((it, i) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <div
            onClick={() => showAllPrice(i)}
            onMouseOver={isDesktopOrLaptop ? () => showAllPrice(i) : () => {}}
            onMouseLeave={isDesktopOrLaptop ? hideAllPrice : () => {}}
            className="item_hidden_container"
          >
            <span
              // onClick={() => showAllPrice(i)}
              className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
            >
              {it.price[0].price}
            </span>
            <img
              src={showMore}
              className={indexPrice === i ? "icon_open_animation" : "icon_open"}
              alt={"icon_open"}
              // effect="blur"
              // loading="lazy"
            />
          </div>
          {/* {indexPrice === i ? ( */}
          <div
            className={
              isDesktopOrLaptop
                ? indexPrice === i
                  ? "open_options"
                  : "open_options_invisible"
                : indexPrice === i
                ? "open_options__small"
                : "open_options_invisible"
            }
          >
            {it.price.map((it) => (
              <>
                <span
                  className={
                    isDesktopOrLaptop ? "item_title_price" : "item_title_small"
                  }
                >
                  {it.title}
                </span>
                <span
                  className={
                    isDesktopOrLaptop ? "item_price_price" : "item_price_small"
                  }
                >
                  {it.price}
                </span>
              </>
            ))}
          </div>
          {/* ) : null} */}
        </div>
      ))}

      <div className={"margin"} />
      {soundInsulationSecond.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}

      <div className={"margin"} />
      {soundInsulationThird.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}

      <div
        className={
          isDesktopOrLaptop
            ? "sound_isolation_title_container"
            : "sound_isolation_title_container_small"
        }
      >
        <span
          className={
            isDesktopOrLaptop
              ? "sound_isolation_title"
              : "sound_isolation_title_small"
          }
        >
          {interiorRestoration.title}
        </span>
      </div>
      {interiorRestoration.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}

      <div
        className={
          isDesktopOrLaptop
            ? "dry_clean_title_container"
            : "dry_clean_title_container_small"
        }
      >
        <span
          className={
            isDesktopOrLaptop
              ? "sound_isolation_title"
              : "sound_isolation_title_small"
          }
        >
          {dryCleaning.title}
        </span>
      </div>
      {dryCleaning.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}

      <div
        className={
          isDesktopOrLaptop
            ? "sound_isolation_title_container"
            : "sound_isolation_title_container_small"
        }
      >
        <span
          className={
            isDesktopOrLaptop
              ? "sound_isolation_title"
              : "sound_isolation_title_small"
          }
        >
          {polishing.title}
        </span>
      </div>
      {polishing.items.map((it) => (
        <div
          className={
            isDesktopOrLaptop ? "item_container" : "item_container_small"
          }
        >
          <span
            className={isDesktopOrLaptop ? "item_title" : "item_title_small"}
          >
            {it.title}
          </span>
          <span
            className={isDesktopOrLaptop ? "item_price" : "item_price_small"}
          >
            {it.price}
          </span>
        </div>
      ))}
      <div className={isDesktopOrLaptop ? "footer_bg" : "footer_bg_small"}>
        {isDesktopOrLaptop ? (
          <FoorterBig contactsRef={contactsRef} />
        ) : (
          <FoorterSmall contactsRef={contactsRef} />
        )}
      </div>
    </div>
  );
};

export default PriceList;
