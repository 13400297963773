import "./SecondBlockBig.css";
import secondBlockImg from "../../assets/secondBlock/imageFirst.png";
import secondBlockImgRight from "../../assets/secondBlock/secondImage.png";

const SecondBlockBig = () => {
  return (
    <div className="second_block">
      <div className="second_block_title_container">
        <p className="second_block_title">
          <span className="second_block_title_yellow">black rock custom</span> -
          это не просто студия детейлинга, а спа-салон для вашего авто.
        </p>
      </div>
      <div className="second_block_image_container">
        <img
          src={secondBlockImg}
          className="second_block_first_image"
          alt="left_image"
          // effect="blur"
          loading="lazy"
        />
        <div className="second_block_right">
          <span className="second_block_text">
            Наша цель - это забота о внешнем виде автомобилей, потому что они
            являются важным элементом имиджа их владельцев.
          </span>
          <img
            src={secondBlockImgRight}
            className="second_block_second_image"
            alt="right_image"
            // effect="blur"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default SecondBlockBig;
