export const soundInsulation = {
  title: "Шумоизоляция",
  items: [
    {
      title: "Шумоизоляция потолка",
      price: "от 100 BYN",
    },
    {
      title: "Шумоизоляция одной двери",
      price: "от 120 BYN",
    },
    {
      title: "Шумоизоляция багажного отделения",
      price: "от 200 BYN",
    },
    {
      title: "Шумоизоляция пола",
      price: "от 300 BYN",
    },
    {
      title: "Шумоизоляция колесных арок",
      price: "от 400 BYN",
    },
  ],
};

export const interiorReupholstery = {
  title: "Перетяжка салона",
  items: [
    {
      title: "Перетяжка сидения (целиком)",
      price: [
        { title: "Кожзам", price: "от 300 BYN" },
        { title: "Экокожа", price: "от 400 BYN" },
        { title: "Алькантара", price: "от 500 BYN" },
        { title: "Натуральная кожа", price: "от 600 BYN" },
      ],
    },
    {
      title: "Перешив всех сидений",
      price: [
        { title: "Кожзам", price: "от 1000 BYN" },
        { title: "Экокожа", price: "от 1900 BYN" },
        { title: "Алькантара", price: "от 2000 BYN" },
        { title: "Натуральная кожа", price: "от 2400 BYN" },
      ],
    },
    {
      title: "Перетяжка руля",
      price: [
        { title: "Кожзам", price: "от 100 BYN" },
        { title: "Экокожа", price: "от 100 BYN" },
        { title: "Алькантара", price: "от 300 BYN" },
        { title: "Натуральная кожа", price: "от 300 BYN" },
      ],
    },
    {
      title: "Перетяжка торепды",
      price: [
        { title: "Кожзам", price: "от 300 BYN" },
        { title: "Экокожа", price: "от 400 BYN" },
        { title: "Алькантара", price: "от 600 BYN" },
        { title: "Натуральная кожа", price: "от 1000 BYN" },
      ],
    },
  ],
};

export const soundInsulationSecond = {
  items: [
    {
      title: "Перетяжка потолка",
      price: "от 200 BYN",
    },
    {
      title: "Перетяжка одной стойки",
      price: "от 25 BYN",
    },
    {
      title: "Перетяжка козырьков",
      price: "от 80 BYN",
    },
    {
      title: "Перетяжка потолка в черный",
      price: "от 500 BYN",
    },
    {
      title: "Перетяжка потолка после срабатывания подушек безопасности",
      price: "от 300 BYN",
    },
  ],
};

export const soundInsulationThird = {
  items: [
    {
      title: "Перетяжка кулисы КПП",
      price: "от 20 BYN",
    },
    {
      title: "Перетяжка ручки КПП",
      price: "от 30 BYN",
    },
    {
      title: "Перетяжка дверной карты",
      price: "от 50 BYN",
    },
    {
      title: "Прочий пластик салона",
      price: "от 30 BYN",
    },
  ],
};

export const interiorRestoration = {
  title: "реставрация кожи",
  items: [
    {
      title: "Реставрация одного элемента сидения",
      price: "от 50 BYN",
    },
    {
      title: "Реставрация низа сидения",
      price: "от 80 BYN",
    },
    {
      title: "Реставрация сидения целиком",
      price: "от 130 BYN",
    },
    {
      title: "Реставрация подлокотника",
      price: "от 40 BYN",
    },
    {
      title: "Реставрация дверной карты",
      price: "от 40 BYN",
    },
    {
      title: "Реставрация руля",
      price: "от 70 BYN",
    },
    {
      title: "Восстановление поролона сидения",
      price: "от 50 BYN",
    },
  ],
};

export const dryCleaning = {
  title: "химчистка",
  items: [
    {
      title: "Комплексная химчистка автомобиля",
      price: "",
    },
    {
      title: "Седан",
      price: "от 200 BYN",
    },
    {
      title: "Минивен",
      price: "от 300 BYN",
    },
  ],
};

export const polishing = {
  title: "полировка",
  items: [
    {
      title: "Легкая",
      price: "от 200 BYN",
    },
    {
      title: "Средняя",
      price: "от 400 BYN",
    },
    {
      title: "Глубокая",
      price: "от 600 BYN",
    },
    {
      title: "Пластик салона",
      price: "от 60 BYN",
    },
  ],
};
